<template>
  <div>
    <v-card class="pa-0">
      <v-img
        class="w-full"
        src="@/assets/images/toppage/cafe.png"
        alt=""
        height="40vw"
      />
      <v-card-title>{{ $t('CouponList') }}</v-card-title>
      <v-card-text>
        <v-select
          v-model="status"
          :loading="infinityScrollBusy"
          :items="allStatus"
          item-text="text"
          item-value="value"
          outlined
          dense
          @change="reload"
        >
        </v-select>
        <template v-if="items.length === 0">
          <div class="font-weight-bold mb-4">
            {{ $t('NoData') }}
          </div>
        </template>
        <v-list
          v-infinite-scroll="loadMore"
          infinite-scroll-disabled="infinityScrollBusy"
          class="pa-0 mx-n4"
        >
          <template v-for="(item, index) in items">
            <v-list-item
              :key="item.id_coupon"
              @click="showDetail(item)"
            >
              <v-list-item-avatar
                rounded
                size="50"
              >
                <v-img
                  src="@/assets/images/toppage/Cafe_cake.png"
                  :width="50"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="mb-2">
                  <span class="">
                    {{ item.name }}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle class="text-xs">
                  <span
                    :class="{'error--text': expired(item.expired_at)}"
                  >
                    {{ $t('ExpirationDate') }}: <strong>{{ formatTimestampToDatetime(item.expired_at) }}</strong>
                  </span>
                  <div
                    v-if="item.is_used"
                    class="text-left success--text mt-1"
                  >
                    {{ $t('UsedDate') }}: <strong>{{ formatTimestampToDatetime(item.used_at) }}</strong>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider
              v-if="index < items.length - 1"
              :key="`divider-${item.id_coupon}`"
              :inset="item.inset"
            ></v-divider>
          </template>
        </v-list>
      </v-card-text>
      <v-dialog
        v-model="isDialogVisible"
        width="500"
      >
        <v-card>
          <v-card-title>
            <span class="mr-6">{{ selected.name }}</span>
            <v-spacer />
            <v-btn
              icon
              absolute
              top
              right
              small
              @click="isDialogVisible = false"
            >
              <v-icon>
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="text-center">
            <div class="mt-4">
              {{ $t('PleaseGiveCodeCoupon') }}
            </div>
            <div class="qrHolder">
              <vue-qr
                :text="selected.code_coupon"
                :size="250"
              ></vue-qr>
              <div
                v-if="selected.is_used || expired(selected.expired_at)"
                class="overlay text-center d-flex align-center justify-center"
              >
                <span
                  v-if="expired(selected.expired_at)"
                  class="text-h3 d-flex align-center justify-center error--text"
                >{{ $t('Expired') }}</span>
                <span
                  v-if="selected.is_used"
                  class="text-h3 d-flex align-center justify-center success--text"
                >{{ $t('Used') }}</span>
              </div>
            </div>
            <div
              class="text-left"
              :class="{'error--text': expired(selected.expired_at)}"
            >
              {{ $t('ExpirationDate') }}: <strong>{{ formatTimestampToDatetime(selected.expired_at) }}</strong>
            </div>
            <div
              v-if="selected.is_used"
              class="text-left success--text"
            >
              {{ $t('UsedDate') }}: <strong>{{ formatTimestampToDatetime(selected.used_at) }}</strong>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>
<script>
import CafeService from '@/services/CafeService'
import Bus from '@/utils/bus'
import { useUtils } from '@core/libs/i18n'
import { mdiClose, mdiEyeOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import VueQr from 'vue-qr'

export default {
  components: { VueQr },
  setup() {
    const { t } = useUtils()

    const allStatus = [
      {
        text: t('All'),
        value: 0,
      },
      {
        text: t('NotExprired'),
        value: 1,
      },
      {
        text: t('Expired'),
        value: 2,
      },

      {
        text: t('Used'),
        value: 3,
      },
    ]

    const status = ref(1)
    const items = ref([])
    const selected = ref({})
    const isDialogVisible = ref(false)
    const infinityScrollBusy = ref(false)
    let stop = false
    let page = 1
    const limit = 10

    const expired = val => !!val && dayjs.unix(val).valueOf() < Date.now()

    const getData = async () => {
      infinityScrollBusy.value = true
      const resp = await CafeService.getUserCoupon({
        page,
        limit,
        status: status.value,
      })

      infinityScrollBusy.value = false

      const { data } = resp.data
      if (data.length === 0) {
        stop = true
      } else {
        items.value = [...items.value, ...data]
        page += 1
        stop = false
        infinityScrollBusy.value = false
      }
    }

    const loadMore = async () => {
      if (!stop) {
        await getData()
      }
    }

    const showDetail = item => {
      isDialogVisible.value = true
      selected.value = item
    }

    const reload = async () => {
      page = 1
      stop = false
      items.value = []
      await getData()
    }

    return {
      allStatus,
      status,
      items,
      selected,
      isDialogVisible,
      infinityScrollBusy,

      loadMore,
      showDetail,
      reload,
      expired,
      icons: {
        mdiEyeOutline,
        mdiClose,
      },
    }
  },
  created() {
    Bus.$on('haveMessage', this.handleMsg)
  },
  destroyed() {
    Bus.$off('haveMessage', this.handleMsg)
  },
  methods: {
    handleMsg(codeEvent) {
      if (codeEvent === 'cafe_002') {
        this.isDialogVisible = false
        this.$set(this, 'selected', {})
        this.reload()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.qrHolder {
  position: relative;
  margin: 20px auto;
  width: auto;
  img {
    display: block;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }
}
</style>
