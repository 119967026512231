/* eslint-disable class-methods-use-this */
import axios from '@/plugins/axios'

class CafeService {
  getUserCoupon(params) {
    return axios.get('/v1/cafe/coupon', { params })
  }

  getStaffInfo(params) {
    return axios.get('/v1/cafe/info', { params })
  }

  getStaffActivity(params) {
    return axios.get('/v1/account/user/activity', { params })
  }

  staffScanCoupon(code) {
    return axios.get(`/v1/cafe/getCoupon/${code}`)
  }

  staffUseCoupon(data) {
    return axios.post('/v1/cafe/use/coupon', data)
  }

  getExportUrl(params) {
    return axios.get('/v1/cafe/coupon/export', { params, responseType: 'blob' })
  }
}

export default new CafeService()
